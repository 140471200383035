<template>
  <Topbar :back-icon="currentCourse ? 'back' : null" />
  <div class="v-course-picker" v-if="coursesAvailable.length">
    <TheHeading class="v-course-picker__title">{{
      $t('MODAL_CHANGE_COURSE')
    }}</TheHeading>
    <ul class="v-course-picker__list">
      <li
        v-for="course in coursesAvailable"
        :key="getCourseByName(course).name"
        class="v-course-picker__list-item"
      >
        <TheButton
          class="v-course-picker__button"
          :class="{
            'v-course-picker__button--selected':
              currentCourse === getCourseByName(course).uid,
          }"
          @click="switchCourse(getCourseByName(course).uid)"
        >
          <img
            :src="getCourseByName(course).cover"
            :alt="getCourseByName(course).name"
            class="v-course-picker__cover"
          />
          <div class="v-course-picker__course-name">
            {{ getCourseByName(course).name }}
          </div>
        </TheButton>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import Topbar from '@/components/Topbar.vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import useCourseHandler from '@/composables/useCourseHandler'
import useAccessCheck from '@/composables/useAccessCheck'

export default defineComponent({
  components: {
    Topbar,
    TheHeading,
    TheButton,
  },
  setup() {
    const { tenant } = useTenant()
    const { currentCourse, switchCourse } = useCourseHandler()
    const { coursesAvailable } = useAccessCheck()
    const { getCourseByName } = useCourseHandler()

    useHead({
      title: computed(() => `${tenant.value.name}`),
    })

    return {
      currentCourse,
      coursesAvailable,
      getCourseByName,
      switchCourse,
    }
  },
})
</script>

<style lang="scss">
.v-course-picker {
  min-height: calc(calc(100vh - $topbar-height) - rem(16px));
  padding: rem(16px);
  padding-top: rem($topbar-height);
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;
}

.v-course-picker__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.v-course-picker__button {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  &--selected {
    border: 0.125rem solid $course-chosen--border;
    background-color: $course-chosen--bg;
  }
}

.v-course-picker__cover {
  width: 100%;
  height: 9rem;
  object-fit: cover;
  border-radius: rem(16px);

  @include bp(medium) {
    height: 12rem;
  }
}

.v-course-picker__course-name {
  margin-top: 1rem;
}
</style>
