
import { defineComponent, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import Topbar from '@/components/Topbar.vue'
import TheHeading from '@/components/TheHeading.vue'
import TheButton from '@/components/TheButton.vue'
import useCourseHandler from '@/composables/useCourseHandler'
import useAccessCheck from '@/composables/useAccessCheck'

export default defineComponent({
  components: {
    Topbar,
    TheHeading,
    TheButton,
  },
  setup() {
    const { tenant } = useTenant()
    const { currentCourse, switchCourse } = useCourseHandler()
    const { coursesAvailable } = useAccessCheck()
    const { getCourseByName } = useCourseHandler()

    useHead({
      title: computed(() => `${tenant.value.name}`),
    })

    return {
      currentCourse,
      coursesAvailable,
      getCourseByName,
      switchCourse,
    }
  },
})
